import Elemental from 'elemental';
import $ from 'jquery';

Elemental.Component.PrivacyModal = class PrivacyModal extends Elemental.BaseComponent {
    static get tagName() {
        return 'rhg-privacy-modal';
    }

    constructor(element) {
        super(element);
    }

    setVars() {
        this.css = {
            selectors: {
                privacyModal: "#privacyModal"
            }
        };

        this.localStorage = {
            keys: {
                privacyPolicy: 'rhgpp'
            }
        };

        this.$modal = $(this.css.selectors.privacyModal);
    }

    setEventListeners() {
        this.$modal.on('hide.bs.modal', this.onHideModal.bind(this));
    }

    onHideModal() {
		window.localStorage.setItem(this.localStorage.keys.privacyPolicy, '1');
    }

    showModal() {
        if (this.$modal.length) {
            this.$modal.modal({
				backdrop: 'static',
				keyboard: false
			});
        }
    }

    init() {
        const seenMsg = window.localStorage.getItem(this.localStorage.keys.privacyPolicy);

		if (!seenMsg) {
			this.showModal();
		}
    }

    render() {
        this.setVars();
        this.setEventListeners();
        this.init();
    }
}
