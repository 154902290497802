import Elemental from 'elemental';
import $ from 'jquery';

Elemental.Component.QuoteButton = class QuoteButton extends Elemental.BaseComponent {
    static get tagName() {
        return 'rhg-quote-button';
    }

    constructor(element) {
        super(element);
    }

    setVars() {
        this.css = {
            classes: {
                active: "active",
                submitted: "submitted"
            },
            selectors: {
                formContent: ".form-content",
                quoteModal: ".modal--quote"
            }
        };
    }

    setEventListeners() {
        this.$element.on('click', this.onClick.bind(this));
    }

    onClick(event) {
        const url = $(event.target).attr('href');
        const dfd = this.getQuotePage(url);

        event.preventDefault();

        dfd.done(this.renderModal.bind(this));
    }

    onSubmit(event) {
        const $form = $(event.currentTarget);
        const $modal = $form.parents(this.css.selectors.quoteModal);
        const $submit = $form.find('button[type=submit]');
        const $success = $('<p />').text('Thanks for your enquiry. One of our team will be in touch very shortly!');

        $submit.prop('disabled', true);

        event.preventDefault();

        $.ajax({
            url: window.location.href,
            type: $form.attr('method'),
            data: $form.serialize()
        }).done(() => {
            $modal.find('.modal-body').html($success);
            $modal.addClass(this.css.classes.submitted);
        });
    }

    getQuotePage(url) {
        return $.ajax({
            url: url
        });
    }

    renderModal(data) {
        const $content = $(data).find(this.css.selectors.formContent);
        const modalId = `modal-${$content.attr('id')}`;
        const $modalTitle = $content.find('h1');
        const modalData = {
            id: modalId,
            title: $modalTitle.text()
        };

        $modalTitle.remove();
        modalData.content = $content.html();

        this.$body.append(this.getModal(modalData));
        this.showModal(modalId);
        let $modal = $(`#${modalId}`);

        $modal.find('input[name="policyType"]').attr('value', this.$element.data('policyType'));
        $modal.find('form').on('submit', this.onSubmit.bind(this));
    }

    showModal(modalId) {
        const $modal = $(`#${modalId}`);

        if ($modal.length) {
            $modal.modal();
        }
    }

    getModal(data) {
        const template = require('../hbs/modal.hbs');

        return template(data);
    }

    render() {
        this.setVars();
        this.setEventListeners();
    }
}
