import $ from 'jquery';

const Elemental = {

    init: function() {
        document.addEventListener('DOMContentLoaded', this.onDOMLoaded.bind(this));
    },

    onDOMLoaded: function(event) {
        this.renderComponents();
    },

    renderComponents: function(context = document) {
        for (let key in this.Component) {
            let elements = context.querySelectorAll(`[data-${this.Component[key].tagName}]`);

            elements = Array.prototype.slice.call(elements);

            if (!this.Component[key].instances) {
                this.Component[key].instances = [];
            }

            elements.forEach(function(element) {
                let component = new this.Component[key](element);

                if (!element.rendered) {
                    element.component = component;
                    component.render();
                    this.Component[key].instances.push(component);
                    element.rendered = true;
                }
            }.bind(this));
        }
    },

    Component: {},

    BaseComponent: class {
        constructor(element) {
            this.element = element;

            if ($) {
                this.$element = $(element);
                this.$window = $(window);
                this.$body = $('body');
            }
        }
    }

};

export default Elemental;
