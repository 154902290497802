import Elemental from 'elemental';
import svg4everybody from 'svg4everybody';

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';

require('../../components/carousel/js/carousel');
require('../../components/quote-button/js/quote-button');
require('../../components/privacy-modal/js/privacy-modal');

Elemental.init();

svg4everybody({
    polyfill: true
});
