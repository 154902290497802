import Elemental from 'elemental';
import $ from 'jquery';
import 'slick-carousel';

Elemental.Component.Carousel = class Carousel extends Elemental.BaseComponent {
    static get tagName() {
        return 'rhg-carousel';
    }

    constructor(element) {
        super(element);
    }

    setVars() {
        this.css = {
            classes: {
                active: "slick-active",
            },
            selectors: {
                carousel: ".carousel",
                initialised: ".slick-initialized"
            }
        };

        this.$carousel = this.$element.find(this.css.selectors.carousel);

        this.options = {
            "arrows": this.$element.data('arrows') || true,
            "autoplay": this.$element.data('autoplay') || false,
            "autoplaySpeed": this.$element.data('autoplaySpeed'),
            "dots": this.$element.data('dots') || false,
            "slidesToShow": this.$element.data('slidesToShow') || 1,
            "slidesToScroll": this.$element.data('slidesToScroll') || 1,
            "speed": this.$element.data('speed'),
            "fade": this.$element.data('fade'),
            "infinite": this.$element.data('infinite') || false,
            "cssEase": "cubic-bezier(0.32, 0.94, 0.6, 1)",
            "prevArrow": this.getArrow('prev'),
            "nextArrow": this.getArrow('next')
        };
    }

    getArrow(direction) {
        const template = require('../hbs/arrows.hbs');
        const data = {
            direction: direction,
            buttonText: this.$element.data(`${direction}Text`),
            icon: (direction === 'next') ? '&raquo;' : '&laquo;'
        };

        return template(data);
    }

    init() {
        this.$carousel.slick(this.options);
    }

    render() {
        this.setVars();
        this.init();
    }
}
